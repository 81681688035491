<template>
  <v-container>
    <div class="ml-2"><PageTitle text="Opening Stocks For Lubes" /></div>
    <div class="buttonText ml-1" id="result">
      <v-chip
        label
        color="accent"
        style="margin-top: 20px; margin-left: 10px"
        class="white--text"
      >
        Opening Stocks For Lubes Per Branch
      </v-chip>
    </div>
    <v-row
      align="center"
      justify="center"
      style="margin-top: 5px; margin-left: 1px; margin-right: 10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <v-card class="elevation-1 pt-5 pb-4" style="border-radius: 8px">
            <ValidationObserver v-slot="{ handleSubmit }">
              <v-form
                @submit.prevent="handleSubmit(saveOpeningStock)"
                class="px-3"
              >
                <v-col>
                  <v-row>
                    <div class="d-flex flex-column flex-fill">
                      <div class="d-flex flex-row flex-grow-1 flex-fill">
                        <ValidationProvider
                          rules="required"
                          class="d-flex flex-row"
                          style="flex-basis: 45%"
                          tag="div"
                          v-slot="{ errors }"
                        >
                          <v-autocomplete
                            :items="filteredBranches"
                            outlined
                            class="mx-2"
                            v-model="branchId"
                            label="Choose A Branch"
                            hint="Select from the list of branches to set the opening stock"
                            persistent-hint
                            item-text="name"
                            :error-messages="errors[0]"
                            item-value="id"
                          />
                        </ValidationProvider>
                      </div>
                      <div
                        class="d-flex flex-column flex-fill pa-4 ma-1"
                        style="background-color: #eeeeee; border-radius: 6px"
                      >
                        <v-card
                          v-for="(row, index) in stockLineItems"
                          :key="index"
                          class="elevation-0 d-flex flex-column flex-fill mt-2 pt-6 mb-2 px-2"
                          style="width: 100%"
                        >
                          <div class="d-flex flex-row flex-grow-1">
                            <ValidationProvider
                              tag="div"
                              class="d-flex flex-row flex-grow-1 ma-2"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <v-autocomplete
                                :items="products"
                                v-model="row.productId"
                                item-text="name"
                                item-value="id"
                                prepend-inner-icon="category"
                                :error-messages="errors[0]"
                                outlined
                                readonly
                                label="Choose A Product"
                                hint="Select from a list of product "
                                class="ft font-weight-medium text-sm"
                              />
                            </ValidationProvider>
                            <ValidationProvider
                              tag="div"
                              rules="required|numeric"
                              v-slot="{ errors }"
                              class="d-flex flex-row flex-grow-1 ma-2"
                            >
                              <v-text-field
                                outlined
                                v-model="row.openingStock"
                                prepend-inner-icon="all_inclusive"
                                label="Enter Opening Stock"
                                :error-messages="errors[0]"
                                class="ft font-weight-medium text-sm"
                              />
                            </ValidationProvider>
                            <ValidationProvider
                              tag="div"
                              class="d-flex flex-row flex-grow-1 ma-2"
                            >
                              <v-text-field
                                outlined
                                v-model="row.minimumThreshold"
                                prepend-inner-icon="all_inclusive"
                                label="Enter Minimum Threshold"
                                class="ft font-weight-medium text-sm"
                              />
                            </ValidationProvider>
                          </div>
                        </v-card>
                      </div>
                    </div>
                  </v-row>
                  <v-row class="mt-5 mr-1">
                    <div
                      class="d-flex flex-row flex-grow-1 flex-fill justify-end"
                    >
                      <v-btn
                        :disabled="isLoading || filteredBranches.length === 0"
                        :loading="isLoading"
                        color="primary"
                        type="submit"
                        >Save
                      </v-btn>
                    </div>
                  </v-row>
                </v-col>
              </v-form>
            </ValidationObserver>
          </v-card>
        </template>
      </v-col></v-row
    >

    <v-snackbar v-model="showSnackBar" :timeout="4000" top :color="status">
      <span class="white--text">{{ message }}</span>
      <v-btn text color="white" @click="showSnackBar = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import PageTitle from "../../../components/PageTitle";
import { timestamp } from "../../../filters/timestamp";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { numeric, required } from "vee-validate/dist/rules";
import { currencyFormat } from "../../../filters/currencyFormat";
import axios from "axios";
extend("required", {
  ...required,
  message: "Field is required"
});
extend("numeric", {
  ...numeric,
  message: "Field accept only numbers"
});
export default {
  name: "List",
  components: {
    PageTitle,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      branchId: "",
      stockLineItems: [],
      products: [],
      branches: [],

      isPageLoading: false,
      isLoading: false,
      showSnackBar: false,
      status: "",
      message: "",
      dialogState: {
        add: false,
        edit: false,
        delete: false
      },
      details: null,
      availableStockList: []
    };
  },
  filters: {
    timestamp,
    currencyFormat
  },
  computed: {
    filteredBranches() {
      return this.branches.filter(
        function(e) {
          return this.indexOf(e.id) < 0;
        },
        [
          ...new Set(
            this.availableStockList.map(stockBranch => stockBranch.branchId.id)
          )
        ]
      );
    }
  },
  methods: {
    async getBranches() {
      try {
        this.isPageLoading = true;
        const response = await axios.get(
          `${process.env.VUE_APP_BASE}/branches`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        );
        if (response) {
          this.isPageLoading = false;
          this.branches = response.data;
        }
      } catch (e) {
        this.isPageLoading = false;
        this.showSnackBar = true;
        this.message = e.message ?? "Unable to get branches";
        this.status = "red";
      }
    },
    async availableStock() {
      try {
        this.isPageLoading = true;
        const response = await axios.get(
          `${process.env.VUE_APP_BASE}/stocks/available?isPaginated=false`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        );
        if (response) {
          this.availableStockList = response.data;
        }
      } catch (e) {
        this.isPageLoading = false;
        this.showSnackBar = true;
        this.message = e.message ?? "Unable to get branches";
        this.status = "red";
      }
    },
    async getLubes() {
      try {
        this.isPageLoading = true;
        const response = await axios.get(
          `${process.env.VUE_APP_BASE}/products/lubes/list`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        );
        if (response) {
          this.isPageLoading = false;
          this.products = response.data;
          this.stockLineItems = response.data.map(product => {
            return {
              productId: product.id,
              minimumThreshold: 0,
              openingStock: 0
            };
          });
        }
      } catch (e) {
        this.isPageLoading = false;
        this.showSnackBar = true;
        this.message = e.message ?? "Unable to get branches";
        this.status = "red";
      }
    },

    async saveOpeningStock() {
      try {
        this.isLoading = true;
        const response = await axios.post(
          `${process.env.VUE_APP_BASE}/stocks/openings/branch/${this.branchId}`,
          {
            stockLineItems: this.stockLineItems
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        );
        if (response) {
          this.isLoading = false;
          this.showSnackBar = true;
          this.message = "Opening Stock successfully set";
          this.status = "success";
        }
      } catch (e) {
        this.isLoading = false;
        this.showSnackBar = true;
        this.message = "Can't set opening stock for product";
        this.status = "success";
      }
    }
  },
  created() {
    this.getBranches();
    this.getLubes();
    this.availableStock();
  }
};
</script>
